body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.stepper {
  padding-bottom: 0.5em;
}
.word-wrap {
  word-wrap: break-word;
  display: block;
  width: 100px;
}
